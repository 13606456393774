import React from "react";
import {downloadSelectedLicensesKey, mainMessages} from "../../assets/text/MultilingualText";
import {downloadLicenses} from "./utilities";
import {Button} from "@progress/kendo-react-buttons";

const GenericIcon = ({className = '', size, iconID, ...props}) => {
    className += " material-symbols"

    if (size === undefined) {
        className += ' medium'
    }

    className += ' ' + size
    return (
        <span
            className={className}
            {...props}
        >
            {iconID}
        </span>
    )
}

export const IconButton = ({Icon, iconStyle= {}, ...props}) => {
    iconStyle['display']= 'flex'
    return (
        <Button
            style={{'padding': '0.375rem'}}
            {...props}
        >
            <Icon size={props.size} style={iconStyle}/>
        </Button>
    )
}

export const HelpIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'Help'} {...props}/>
    )
}


export const SuccessIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'check_circle'} {...props}/>
    )
}

export const WarningErrorIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'warning'} {...props}/>
    )
}

export const CloseIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'close'} {...props}/>
    )
}


export const GearsIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'manufacturing'} {...props}/>
    )
}

export const InfoIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'info'} {...props}/>
    )
}


export const ClockIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'schedule'} {...props}/>
    )
}

export const HomeIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'home'} {...props}/>
    )
}

export const ForwardIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'forward'} {...props}/>
    )
}

export const DownloadIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'download'} {...props}/>
    )
}

export const MailIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'mail'} {...props}/>
    )
}

export const DeleteIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'delete'} {...props}/>
    )
}

export const CartIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'shopping_cart'} {...props}/>
    )
}

export const HyperLinkIcon = ({...props}) => {
    return (
        <GenericIcon iconID={'open_in_new'} {...props}/>
    )
}