import React, {createContext, useCallback, useContext, useEffect, useRef, useState} from 'react';
import UserContext from '../common/UserContext';
import * as XLSX from "xlsx";
import * as FileSaver from 'file-saver';


// Components
import {
    CurrencyCell,
    DateCell,
    NoWrapCell,
    TextAlignMiddleCell,
    TextCapitalizeCell
} from "../common/Grid";
import {HostPanelBarHeader} from "./HostPanelbarHeader";
import {HostDetailPopover} from "./HostPopovers";
import {capitalizeSentence, downloadLicenses, formatCurrency} from "../common/utilities";
import {EmailDialog} from "../common/EmailModal";
import Alert from "../common/Alert";

// kendo
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {orderBy} from "@progress/kendo-data-query";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";

// Multilingual
import {
    downloadSelectedLicensesKey,
    emailSelectedLicensesKey,
    exportToExcelKey,
    costPerUnitKey,
    descriptionKey,
    endDateKey,
    licenseTypeKey,
    productNumberKey,
    qtyKey,
    startDateKey,
    totalCostKey,
    noLicensesPeriodKey,
    mainMessages,
    emailReceiveKey,
    emailSuccessKey,
    assignMoreKey
} from "../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";
import {useHistory} from "react-router-dom";
import {fileExcelIcon} from "@progress/kendo-svg-icons";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {DownloadIcon, IconButton, MailIcon} from "../common/icons";


// context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null,
});

const HostPanelBars = (props) => {
    const {
        eaID,
        groupByHosts,
        setGroupByHosts,
        groupByHostsNoExpiredProducts,
        setGroupByHostsNoExpiredProducts,
        showExpired,
        isLoading,
        setIsLoading,
        eaDetails,
        periods,
        selectedPeriod,
        showEmailSuccess,
        setShowEmailSuccess,
        rowRender
    } = props
    let history = useHistory();
    const {siteLanguageDefault, accessToken} = useContext(UserContext);
    const localization = useLocalization();
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [items, setItems] = useState(groupByHosts)

    //Closes the success email alert
    const alertEmailSuccessHandler = () => {
        setShowEmailSuccess(false);
    }

    //Show download button if period is not expired
    const [showEmailDownloadButtons, setShowEmailDownloadButtons] = useState(true)
    useEffect(() => {
        let currentDate = new Date()
        currentDate.setHours(0, 0, 0, 0)
        const periodNotExpired = currentDate <= new Date(selectedPeriod.period_end_date)
        setShowEmailDownloadButtons(periodNotExpired)
    }, [selectedPeriod])

    // Update items based on check box clicked and show expired
    useEffect(() => {
        let newItems = []

        if (showExpired) {
            newItems = JSON.parse(JSON.stringify(groupByHosts))
        } else {
            newItems = JSON.parse(JSON.stringify(groupByHostsNoExpiredProducts))
        }

        newItems.forEach(item => {
            let hostIndex = groupByHosts.findIndex(host => host.name === item.name)
            groupByHosts[hostIndex].icons = item.icons
            setGroupByHosts(groupByHosts)

            hostIndex = groupByHostsNoExpiredProducts.findIndex(host => host.name === item.name)
            if (hostIndex !== -1) {
                groupByHostsNoExpiredProducts[hostIndex].icons = item.icons
                setGroupByHostsNoExpiredProducts(groupByHostsNoExpiredProducts)
            }
        })

        if (showExpired) {
            setItems(JSON.parse(JSON.stringify(groupByHosts)))
        } else {
            setItems(JSON.parse(JSON.stringify(groupByHostsNoExpiredProducts)))
        }

    }, [showExpired, groupByHosts, groupByHostsNoExpiredProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    const [showPopover, setShowPopover] = useState(false);
    const [popoverType, setPopoverType] = useState('special');
    const [popoverRef, setPopoverRef] = useState();
    //Sets which popover to show and it's corresponding reference
    const handlePopoverMouseOver = useCallback(
        (event) => {
            if (event.show) {
                setShowPopover(true);
            } else {
                setShowPopover(false);
            }

            if (event.banked) {
                setPopoverType('banked');
            } else if (event.special) {
                setPopoverType('special');
            } else if (event.cloud) {
                setPopoverType('cloud');
            }

            setPopoverRef(event.popoverRef);
        },
        [setShowPopover, setPopoverType, setPopoverRef]
    );

    // Handles panel bar expanding based on whether icons have been clicked or not
    const iconClicked = useRef(false)
    const handleSelect = (event) => {
        let name = event.target.props.children[0].props.children.props.data[0].name
        let hostIndex = items.findIndex(host => host.name === name)
        if (!iconClicked.current) {
            if (items[hostIndex].expanded.length === 0) {
                items[hostIndex].expanded = ['.0']
            } else {
                items[hostIndex].expanded = []
            }
        } else {
            iconClicked.current = false
        }
        setItems([...items])
    };

    const [checkedHosts, setCheckedHosts] = useState([])
    const [checkedDownloadableHosts, setCheckedDownloadableHosts] = useState([])

    useEffect(() => {
        let newCheckedHosts = []
        let newCheckedDownloadableHosts = []

        items.forEach(item => {
            if (item.icons.checked) {
                newCheckedHosts.push(item)
            }
            if (item.icons.checked && item.icons.download) {
                item.products.forEach(product => {
                    if (product.candownload === 'YES') {
                        newCheckedDownloadableHosts.push(product.ea_alloc_id.toString())
                    }
                })
            }
        })

        setCheckedHosts(newCheckedHosts)
        setCheckedDownloadableHosts(newCheckedDownloadableHosts)
    }, [items])

    const formatExcelDate = (date) => {
        let dateSplit = new Date(date).toLocaleDateString('en-us', {
            day: 'numeric',
            month: 'short',
            year: 'numeric'
        }).replace(",", "").split(" ");
        if (dateSplit[1].length === 1) {
            dateSplit[1] = "0" + dateSplit[1];
        }
        return dateSplit[1] + "/" + dateSplit[0] + "/" + dateSplit[2]
    }

    const exportExcel = () => {

        let headerData = [{
            "Company Name": eaDetails['customer_name'],
            "Address": eaDetails['customer_address'],
            "City": eaDetails['customer_city'],
            "State": eaDetails['customer_state'],
            "Zip Code": eaDetails['customer_zip_code'],
            "Country": eaDetails['customer_country'],
            "Customer Email": eaDetails['customer_email'],
            "Start Date": formatExcelDate(eaDetails['start_date']),
            "End Date": formatExcelDate(eaDetails['end_date']),
            "Remix Type": eaDetails['remix_type'],
            "Currency Code": eaDetails['currency_code']
        }]

        let periodData = []
        let uniqueSubYears = new Set()
        let subPoolYears = {}
        periods.forEach(period => {
            uniqueSubYears.add(period.subs_year)
            if(!subPoolYears.hasOwnProperty(period.subs_year)){
                subPoolYears[period.subs_year] = period.total_subscription_pool
            }
            periodData.push({
                'Period Number': period.ea_period_id,
                'Year Number': period.subs_year,
                'Start Date': formatExcelDate(period.period_start_date),
                'End Date': formatExcelDate(period.period_end_date),
            })
        })

        uniqueSubYears = Array.from(uniqueSubYears).sort((a, b) => a - b);
        for (const year of uniqueSubYears) {
            headerData[0]['Subs Pool Year ' + year] = formatCurrency(subPoolYears[year], eaDetails['locale'], eaDetails['currency_code'])
        }

        let productData = []
        checkedHosts.forEach(host => {
            host.products.forEach(product => {
                productData.push({
                    'Host ID': product.nameExcel,
                    'Product': product.prod_num,
                    'Description': product.description,
                    'Qty': product.quantity,
                    'Start Date': formatExcelDate(product.start_date),
                    'End Date': formatExcelDate(product.end_date),
                    'License Type': capitalizeSentence(product.license_type),
                    'Cost Per Unit': product.price,
                    'Total Cost': product.total_cost
                })
            })
        })


        let currentDate = new Date();
        let periodName = ''
        if (eaDetails.remix_type.toLowerCase() === 'variable') {
            periodName = '00'
        } else {
            periodName = selectedPeriod.ea_period_id.toString()
            if (periodName.length === 1) {
                periodName = '0' + periodName
            }
        }
        const fileName = 'KSM_EA_DETAILS_' + periodName +
            '_' + currentDate.getFullYear().toString() +
            (currentDate.getMonth() + 1).toString() +
            currentDate.getDate().toString()
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let worksheet = XLSX.utils.json_to_sheet(headerData);
        if (eaDetails.remix_type.toLowerCase() === 'variable') {
            periodData = []
        } else {
            XLSX.utils.sheet_add_json(worksheet, periodData, {origin: "A4"});
        }
        XLSX.utils.sheet_add_json(worksheet, productData, {origin: "A" + (headerData.length + 2 + periodData.length + 2 + 1).toString()});
        const wb = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer = XLSX.write(wb, {bookType: 'xlsx', type: 'array'});
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <PopoverContext.Provider
                value={{
                    onMouseOver: handlePopoverMouseOver
                }}
            >
                {items.map((item, index) => {
                    return (
                        <PanelBar
                            key={index}
                            isControlled={true}
                            expanded={item.expanded}
                            onSelect={handleSelect}
                            className={'ksm-panelbar-default'}
                        >
                            <PanelBarItem
                                title={<HostPanelBarHeader
                                    items={items}
                                    index={index}
                                    setItems={setItems}
                                    setIsLoading={setIsLoading}
                                    iconClicked={iconClicked}
                                />}
                            >
                                <Grid
                                    className={'group-host-panelbar-grid'}
                                    data={orderBy(item.products, [{
                                        field: "product_number",
                                        dir: "desc"
                                    }])}
                                    rowRender={rowRender}
                                    scrollable={'none'}
                                    sortable={false}
                                >
                                    <GridColumn
                                        field="prod_num"
                                        title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                        cell={NoWrapCell}
                                    />
                                    <GridColumn
                                        field="description"
                                        title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                    />
                                    <GridColumn
                                        field="quantity"
                                        title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                        cell={TextAlignMiddleCell}
                                    />
                                    <GridColumn
                                        field="start_date"
                                        title={localization.toLanguageString(startDateKey, mainMessages[siteLanguageDefault][startDateKey])}
                                        cell={DateCell}
                                    />
                                    <GridColumn
                                        field="end_date"
                                        title={localization.toLanguageString(endDateKey, mainMessages[siteLanguageDefault][endDateKey])}
                                        cell={DateCell}
                                    />
                                    <GridColumn
                                        field="license_type"
                                        title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                                        cell={TextCapitalizeCell}
                                    />
                                    <GridColumn
                                        field="price"
                                        title={localization.toLanguageString(costPerUnitKey, mainMessages[siteLanguageDefault][costPerUnitKey])}
                                        cell={CurrencyCell}
                                    />
                                    <GridColumn
                                        field="total_cost"
                                        title={localization.toLanguageString(totalCostKey, mainMessages[siteLanguageDefault][totalCostKey])}
                                        cell={CurrencyCell}
                                    />
                                </Grid>
                            </PanelBarItem>
                        </PanelBar>
                    )
                })}
            </PopoverContext.Provider>

            <HostDetailPopover
                type={popoverType}
                showPopover={true}
                popoverRef={popoverRef}
            />

            {items.length === 0 && !isLoading && (
                <>
                    <b style={{
                        color: "var(--color-alert-danger-base)",
                    }}>
                        {localization.toLanguageString(noLicensesPeriodKey, mainMessages[siteLanguageDefault][noLicensesPeriodKey])}
                    </b>
                    <br/>
                    <br/>
                </>
            )}


            <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row-reverse',
            }}>
                <div style={{
                    display: 'flex',
                    gap: '0.5rem',
                }}>

                    <div>
                        <Tooltip
                            anchorElement="target"
                            showCallout={true}
                            parentTitle={true}
                            openDelay={0}
                            position="left"
                        >
                            <Button
                                title={localization.toLanguageString(exportToExcelKey, mainMessages[siteLanguageDefault][exportToExcelKey])}
                                themeColor={"primary"}
                                size={"large"}
                                fillMode={"solid"}
                                onClick={exportExcel}
                                svgIcon={fileExcelIcon}
                            />
                        </Tooltip>
                    </div>

                    {eaDetails.status.toLowerCase() !== 'expired' && showEmailDownloadButtons && items.length !== 0 && (
                        <>
                            <div>
                                <Tooltip
                                    anchorElement="target"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                    position="left"
                                >
                                    <IconButton
                                        title={localization.toLanguageString(emailSelectedLicensesKey, mainMessages[siteLanguageDefault][emailSelectedLicensesKey])}
                                        themeColor={"primary"}
                                        fillMode={"solid"}
                                        size={"large"}
                                        disabled={checkedDownloadableHosts.length === 0}
                                        onClick={() => {
                                            setShowEmailModal(true)
                                        }}
                                        Icon={MailIcon}
                                    />
                                </Tooltip>
                            </div>

                            <div>
                                <Tooltip
                                    anchorElement="target"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                    position="left"
                                >
                                    <IconButton
                                        title={localization.toLanguageString(downloadSelectedLicensesKey, mainMessages[siteLanguageDefault][downloadSelectedLicensesKey])}
                                        themeColor={"primary"}
                                        fillMode={"solid"}
                                        size={"large"}
                                        disabled={checkedDownloadableHosts.length === 0}
                                        onClick={() => {
                                            downloadLicenses(checkedDownloadableHosts, accessToken, setIsLoading, null, "ENTERPRISE_AGREEMENTS");
                                        }}
                                        Icon={DownloadIcon}
                                    />
                                </Tooltip>
                            </div>
                        </>
                    )}
                </div>

                {eaDetails.status.toLowerCase() !== 'expired' &&
                    (selectedPeriod['period_status'].toLowerCase() === "current" || selectedPeriod['period_status'].toLowerCase() === "open") && (
                        <Button
                            themeColor={"primary"}
                            size={"large"}
                            fillMode={"solid"}
                            disabled={selectedPeriod['remain_subscription_pool'] === 0}
                            onClick={() => {
                                if (selectedPeriod['period_status'].toLowerCase() === "current") {
                                    let params = '?eaid=' + eaID + '&show=PRODUCTSELECTIONCURRENTPERIOD'
                                    history.push('/ea-request-license' + params)
                                } else if (selectedPeriod['period_status'].toLowerCase() === "open") {
                                    let params = '?eaid=' + eaID + '&show=PRODUCTSELECTIONNEXTPERIOD'
                                    history.push('/ea-request-license' + params)
                                }
                            }}
                        >
                            {localization.toLanguageString(assignMoreKey, mainMessages[siteLanguageDefault][assignMoreKey])}
                        </Button>
                    )}
            </div>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '0.938rem'
            }}>
                {showEmailSuccess && (
                    <Alert
                        type={'success'}
                        showHandler={alertEmailSuccessHandler}
                        title={localization.toLanguageString(emailSuccessKey, mainMessages[siteLanguageDefault][emailSuccessKey])}
                        message={localization.toLanguageString(emailReceiveKey, mainMessages[siteLanguageDefault][emailReceiveKey])}
                    />
                )}
            </div>

            {showEmailModal && (
                <EmailDialog
                    setShowEmailModal={setShowEmailModal}
                    setShowEmailSuccess={setShowEmailSuccess}
                    transactionIDs={checkedDownloadableHosts}
                    setIsLoading={setIsLoading}
                    request_source='ENTERPRISE_AGREEMENTS'
                />
            )}
        </>
    )
}

export default HostPanelBars;