import React, {useCallback, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext';
import axios from "axios";
import config from "../../config";

//components
import SearchProducts from "./SelectProducts/SearchProducts";
import {StatusCell} from "./SelectProducts/StatusCell";
import {exportExcel, formatCurrency, multiplyMoney} from '../common/utilities'
import {DeleteModal} from "./SelectProducts/DeleteModal";
import Alert from "../common/Alert";
import {ColumnMenu, CurrencyCell, NoWrapCell, TextAlignMiddleCell} from "../common/Grid";

//kendo
import {process} from "@progress/kendo-data-query";
import {Tooltip} from "@progress/kendo-react-tooltip";
import {Button} from "@progress/kendo-react-buttons";
import {Grid, GridColumn, GridItemChangeEvent} from "@progress/kendo-react-grid";

//multilingual
import {
    costPerUnitKey,
    descriptionKey,
    exportSelectionsKey,
    licenseTypeKey,
    mainMessages,
    myCartKey,
    productNumberKey,
    productsHostKey,
    continueKey,
    qtyKey,
    statusKey,
    removeProductKey,
    totalKey,
    genericErrorTitleKey,
    contactUsHeaderKey,
    changeCannotSaveKey, changeAnotherSessionKey
} from "../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";
import {SvgIcon} from "@progress/kendo-react-common";
import {fileExcelIcon, trashIcon} from "@progress/kendo-svg-icons";
import {DeleteIcon} from "../common/icons";


const SelectProducts = (props) => {
    const {
        siteLanguageDefault,
        accessToken
    } = useContext(UserContext);

    const {
        selectedProducts,
        setSelectedProducts,
        headerInfo,
        setHeaderInfo,
        softwareFamily,
        setIsLoading,
        assignedProducts,
        setAssignedProducts,
        preprocessNewProducts,
        unsavedChanges,
        setUnSavedChanges,
        saveSelections,
        showSaveSelectionGeneralErrorAlert,
        setShowSaveSelectionGeneralErrorAlert,
        showSaveSelectionOverErrorAlert,
        setShowSaveSelectionOverErrorAlert,
        unSavedPool,
        stepperItems,
        setStepperItems
    } = props

    const localization = useLocalization();

    const alertSaveSelectionGeneralErrorHandler = () => {
        setShowSaveSelectionGeneralErrorAlert(false)
    }

    const alertSaveSelectionOverErrorHandler = () => {
        setShowSaveSelectionOverErrorAlert(false)
    }

    const initialGridState = {
        take: 10, skip: 0,
    };
    const [selectedGridState, setSelectedGridState] = useState(initialGridState);
    const [selectedProductState, setSelectedProductState] = useState(
        process(selectedProducts.map((product) => ({
            ...product,
        })), initialGridState)
    );

    /*
     * onGridStateChange(event) sets the grid state and data state on grid's state change
     * @param {event} the grid selection change event
    */
    const onProductStateChange = useCallback((event) => {
        const newProductState = process(
            selectedProducts.map((product) => ({
                ...product,
            })),
            event.dataState
        );
        setSelectedGridState(event.dataState);
        setSelectedProductState(newProductState);
    }, [selectedProducts]);

    //Update search product state when values in searched product gets updated
    useEffect(() => {
        const newProductState = process(
            selectedProducts.map((product) => ({
                ...product,
            })),
            selectedGridState
        );
        setSelectedProductState(newProductState);
    }, [selectedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    //update selected product values based on user input
    const onItemChange = (e: GridItemChangeEvent) => {
        let newData = selectedProducts.map((item) => {
            if (item.ea_alloc_id === e.dataItem.ea_alloc_id) {
                //Update user input values
                if (e.field === 'license_type') {
                    let selectExist = false
                    item.license_type.forEach(type => {
                        type.selected = false
                        if (type.text === 'Select') {
                            selectExist = true
                        }
                        if (type.text === e.value.text) {
                            type.selected = true
                        }
                    })
                    if (selectExist) {
                        item.license_type.shift()
                    }
                } else {
                    item[e.field || ''] = e.value;
                }

                //update per unit cost, total cost, and subscription pool values
                for (const type of item.license_type) {
                    if (type.selected) {
                        let minQty = 0
                        for (const allocId in item.status_display) {
                            minQty += item.status_display[allocId].assign_quantity
                        }
                        let basePrice = type.base_price
                        let quantity = parseInt(item.quantity) ? parseInt(item.quantity) : minQty
                        if (quantity < minQty) {
                            quantity = minQty
                        }
                        const newUnsavedCost = calculateCost()
                        item.subPoolExceed = headerInfo.selected_sub_pool + newUnsavedCost > headerInfo.remaining_pool;
                        item.quantity = quantity
                        item.unassigned_quantity = quantity - minQty
                        item.base_price = basePrice
                        item.total_cost = multiplyMoney(basePrice, quantity)
                        setHeaderInfo(headerInfo)
                    }
                }

                //Update unsaved changes
                unsavedChanges.add(item.ea_alloc_id)
                setUnSavedChanges(unsavedChanges)
            }
            return item
        });
        setSelectedProducts([...newData]);
    };

    //Disable buttons for product selection save if a product is over subpool
    const [disableProductSelectionSave, setDisableProductSelectionSave] = useState(false)
    useEffect(() => {
        let exceedSubPool = headerInfo.selected_sub_pool + unSavedPool > headerInfo.remaining_pool
        if (exceedSubPool) {
            stepperItems[1].disabled = true
            setDisableProductSelectionSave(true)
        } else {
            if (selectedProducts.length > 0) {
                stepperItems[1].disabled = false
                setDisableProductSelectionSave(false)
            }
            for (const product of selectedProducts) {
                product.subPoolExceed = false
            }
            setSelectedProductState([...selectedProducts])
        }
        setStepperItems(stepperItems)
    }, [headerInfo, unSavedPool]) // eslint-disable-line react-hooks/exhaustive-deps

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteProduct, setDeleteProduct] = useState()
    const [showDeleteErrorAlert, setShowDeleteErrorAlert] = useState(false)
    const alertDeleteHandler = () => {
        setShowDeleteErrorAlert(false)
    }

    const deleteAllocations = (deleteProduct, deleteAssigned, modal = true) => {
        let deletes = [{
            'ea_alloc_id': deleteProduct.ea_alloc_id,
            'status': deleteProduct.status
        }]

        if (deleteAssigned) {
            for (const allocId in deleteProduct.status_display) {
                const product = deleteProduct.status_display[allocId]
                deletes.push({
                    'ea_alloc_id': product.ea_alloc_id.toString(),
                    'status': product.status
                })
            }
        }

        let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + accessToken
        };
        setIsLoading(true);

        let location = window.location
        let query = new URLSearchParams(location.search);
        const eaID = query.get('eaid');
        const periodType = query.get('show');

        let body = {
            module: "EA",
            sub_module: "allocations",
            action: "DELETE",
            input_json: [
                {
                    ea_id: eaID,
                    period_selection: periodType,
                    ea_products: deletes
                }
            ]
        }

        axios.delete(
            config.ea_request_license.SAVE_DELETE,
            {headers: headers, data: body}
        )
            .then((response) => {
                if (response.status === 200) {
                    let data = response.data
                    let eaHeaderInfo = data['sub_pool_data'][0]
                    headerInfo.total_pool = eaHeaderInfo.total_pool
                    headerInfo.selected_sub_pool = eaHeaderInfo.selected_sub_pool
                    headerInfo.consumed_pool = eaHeaderInfo.redeemed_pool
                    headerInfo.remaining_pool = eaHeaderInfo.remaining_pool
                    setHeaderInfo(JSON.parse(JSON.stringify(headerInfo)))

                    if (deleteAssigned) {
                        //delete from assigned products
                        for (const allocId in deleteProduct.status_display) {
                            const product = deleteProduct.status_display[allocId]
                            const hostIndex = assignedProducts.findIndex(item => item.transaction_id === product.transaction_id)
                            // console.log('hostIndex', hostIndex, assignedProducts[hostIndex])
                            const productIndex = assignedProducts[hostIndex].products.findIndex(item => item.ea_alloc_id === product.ea_alloc_id)
                            assignedProducts[hostIndex].products.splice(productIndex, 1)
                            if (assignedProducts[hostIndex].products.length === 0) {
                                assignedProducts.splice(hostIndex, 1)
                            }
                        }
                        setAssignedProducts([...assignedProducts])

                        //delete from selected products
                        const productIndex = selectedProducts.findIndex(item => item.ea_alloc_id === deleteProduct.ea_alloc_id)
                        selectedProducts.splice(productIndex, 1)
                        setSelectedProducts([...selectedProducts])
                    } else {
                        let assignedQty = 0
                        const productIndex = selectedProducts.findIndex(item => item.ea_alloc_id === deleteProduct.ea_alloc_id)
                        for (const allocId in deleteProduct.status_display) {
                            const product = deleteProduct.status_display[allocId]
                            assignedQty += product.assign_quantity
                        }
                        deleteProduct.quantity = assignedQty
                        deleteProduct.unassigned_quantity = 0
                        selectedProducts[productIndex] = deleteProduct

                        if (deleteProduct.quantity === 0) {
                            selectedProducts.splice(productIndex, 1) //delete from selected products
                        }
                        setSelectedProducts([...selectedProducts])
                    }
                    setShowDeleteModal(false)
                }
            })
            .catch((error) => {
                console.log("ERROR: Failed to save new product", error);
                if (modal) {
                    setShowDeleteErrorAlert(true)
                } else {
                    setShowSaveSelectionGeneralErrorAlert(true)
                }
            })
            .finally(() => {
                setIsLoading(false)
            })
    }

    const calculateCost = () => {
        let unSavedCost = 0
        for (const product of selectedProducts) {
            const savedQty = product.saved_quantity
            const savedPrice = product.saved_cost
            const savedCost = multiplyMoney(savedPrice, savedQty)

            let newQty = product.quantity
            const licenseType = product.license_type.filter(lt => lt.selected)
            const newPrice = licenseType[0].base_price
            const newCost = multiplyMoney(newPrice, newQty)
            // console.log('newCost', newCost, 'savedCost', savedCost)
            unSavedCost += newCost - savedCost
        }
        return unSavedCost
    }

    //Calculate unsaved cost
    // useEffect(() => {
    //     const unSavedCost = calculateCost()
    //     setUnSavedPool(unSavedCost)
    // }, [selectedProducts]) // eslint-disable-line react-hooks/exhaustive-deps

    // delete cell with functionality to manipulate assigned products json state
    const deleteCell = (props) => {
        const {
            dataItem
        } = props

        return (
            <td>
                <Tooltip
                    anchorElement="target"
                    showCallout={true}
                    parentTitle={true}
                    openDelay={0}
                    position="top"
                >
                    <div
                        title={localization.toLanguageString(removeProductKey, mainMessages[siteLanguageDefault][removeProductKey])}
                        onClick={() => {
                            if (Object.keys(dataItem.status_display).length === 0) {
                                deleteAllocations(dataItem, false, false)
                            } else {
                                setDeleteProduct(dataItem)
                                setShowDeleteModal(true)
                            }
                        }}
                    >
                        <DeleteIcon
                            className={'ksm-grid-icon'}
                        />
                    </div>
                </Tooltip>
            </td>
        )
    }

    const exportSelectedProducts = () => {
        let exportData = []
        for (const product of selectedProducts) {
            let licenseType = ''
            for (const item of product.license_type) {
                if (item.selected) {
                    licenseType = item.text
                }
            }
            exportData.push({
                "Product Number": product.prod_num_display,
                "Description": product.description,
                "License Type": licenseType,
                "Qty": product.quantity,
                "Unit Price": formatCurrency(product.base_price, headerInfo.locale, headerInfo.currency),
                "Total Price": formatCurrency(product.total_cost, headerInfo.locale, headerInfo.currency)
            })
        }
        exportExcel(exportData, "KSM_EA_SELECTION")
    }

    return (
        <div>
            {selectedProducts.length > 0 && (
                <>
                    <div>
                        <div
                            className={'k-h5'}
                            style={{
                                marginBottom: '0.5rem'
                            }}
                        >
                            {localization.toLanguageString(myCartKey, mainMessages[siteLanguageDefault][myCartKey])}
                        </div>

                        <Grid
                            className={'my-selected-grid'}
                            scrollable={'none'}
                            style={{
                                marginBottom: '0.5rem'
                            }}
                            data={selectedProductState}
                            sortable={true}
                            pageable={(selectedProducts.length <= initialGridState.take) ? false : {
                                buttonCount: 5,
                                pageSizes: [10, 20, 50, 100],
                            }}
                            onDataStateChange={onProductStateChange}
                            total={selectedProductState.total}
                            editField="inEdit"
                            onItemChange={onItemChange}
                            {...selectedGridState}
                        >
                            <GridColumn
                                field="prod_num_display"
                                title={localization.toLanguageString(productNumberKey, mainMessages[siteLanguageDefault][productNumberKey])}
                                columnMenu={ColumnMenu}
                                cell={NoWrapCell}
                            />
                            <GridColumn
                                field="description"
                                title={localization.toLanguageString(descriptionKey, mainMessages[siteLanguageDefault][descriptionKey])}
                                columnMenu={ColumnMenu}
                            />
                            <GridColumn
                                field="quantity"
                                title={localization.toLanguageString(qtyKey, mainMessages[siteLanguageDefault][qtyKey])}
                                cell={TextAlignMiddleCell}
                                // cell={AssignQtyCell}
                                sortable={false}
                            />
                            <GridColumn
                                // field="license_type"
                                field={"selected_license_type_text"}
                                title={localization.toLanguageString(licenseTypeKey, mainMessages[siteLanguageDefault][licenseTypeKey])}
                                // cell={LicenseTypeCell}
                                cell={NoWrapCell}
                                sortable={false}
                            />
                            <GridColumn
                                field="base_price"
                                title={localization.toLanguageString(costPerUnitKey, mainMessages[siteLanguageDefault][costPerUnitKey])}
                                cell={CurrencyCell}
                                sortable={false}
                            />
                            <GridColumn
                                field="total_cost"
                                title={localization.toLanguageString(totalKey, mainMessages[siteLanguageDefault][totalKey])}
                                cell={CurrencyCell}
                                sortable={false}
                            />
                            <GridColumn
                                field={'status_display'}
                                title={localization.toLanguageString(statusKey, mainMessages[siteLanguageDefault][statusKey])}
                                cell={StatusCell}
                                sortable={false}
                            />
                            <GridColumn
                                field={null}
                                title={" "}
                                cell={deleteCell}
                                sortable={false}
                            />
                        </Grid>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'end',
                            gap: '0.5rem'
                        }}>
                            <div style={{
                                display: 'flex',
                                gap: '0.5rem'
                            }}>
                                {/*<Button*/}
                                {/*    style={{*/}
                                {/*        width: '10.375rem'*/}
                                {/*    }}*/}
                                {/*    themeColor={"primary"}*/}
                                {/*    size={"large"}*/}
                                {/*    fillMode={"outline"}*/}
                                {/*    rounded={"small"}*/}
                                {/*    disabled={unsavedChanges.size === 0 || disableProductSelectionSave}*/}
                                {/*    onClick={() => {*/}
                                {/*        saveSelections(0)*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    {localization.toLanguageString(saveSelectionsKey, mainMessages[siteLanguageDefault][saveSelectionsKey])}*/}

                                {/*</Button>*/}
                                <Button
                                    themeColor={"primary"}
                                    size={"large"}
                                    fillMode={"solid"}
                                    disabled={disableProductSelectionSave}
                                    onClick={() => {
                                        saveSelections(1)
                                    }}
                                >
                                    {localization.toLanguageString(continueKey, mainMessages[siteLanguageDefault][continueKey])}
                                </Button>
                            </div>
                            <div>
                                <Tooltip
                                    anchorElement="target"
                                    showCallout={true}
                                    parentTitle={true}
                                    openDelay={0}
                                    position="left"
                                >
                                    <Button
                                        title={localization.toLanguageString(exportSelectionsKey, mainMessages[siteLanguageDefault][exportSelectionsKey])}
                                        themeColor={"tertiary"}
                                        fillMode={"solid"}
                                        size={"large"}
                                        onClick={exportSelectedProducts}
                                        svgIcon={fileExcelIcon}
                                    />
                                </Tooltip>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '0.5rem'
                        }}>
                            {showSaveSelectionGeneralErrorAlert && (
                                <Alert
                                    type={'error'}
                                    showHandler={alertSaveSelectionGeneralErrorHandler}
                                    title={localization.toLanguageString(genericErrorTitleKey, mainMessages[siteLanguageDefault][genericErrorTitleKey])}
                                    message={localization.toLanguageString(contactUsHeaderKey, mainMessages[siteLanguageDefault][contactUsHeaderKey])}
                                />
                            )}

                            {showSaveSelectionOverErrorAlert && (
                                <Alert
                                    type={'error'}
                                    showHandler={alertSaveSelectionOverErrorHandler}
                                    title={localization.toLanguageString(changeCannotSaveKey, mainMessages[siteLanguageDefault][changeCannotSaveKey])}
                                    message={localization.toLanguageString(changeAnotherSessionKey, mainMessages[siteLanguageDefault][changeAnotherSessionKey])}
                                />
                            )}

                        </div>
                    </div>
                    <div className={'k-hr'} />
                </>
            )}


            <SearchProducts
                selectedProducts={selectedProducts}
                setSelectedProducts={setSelectedProducts}
                softwareFamily={softwareFamily}
                headerInfo={headerInfo}
                setHeaderInfo={setHeaderInfo}
                setIsLoading={setIsLoading}
                preprocessNewProducts={preprocessNewProducts}
                unSavedPool={unSavedPool}
            />

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '1.875rem'
            }}>
                <div>
                    {headerInfo.ea_legal_disclosure}
                </div>
            </div>

            {showDeleteModal && (
                <DeleteModal
                    setShowModal={setShowDeleteModal}
                    deleteProduct={deleteProduct}
                    deleteAllocations={deleteAllocations}
                    showDeleteErrorAlert={showDeleteErrorAlert}
                    alertDeleteHandler={alertDeleteHandler}
                />
            )}
        </div>
    )
}

export default SelectProducts;
