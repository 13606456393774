import React, {createContext, useContext, useEffect, useState} from 'react';
import UserContext from '../common/UserContext';

// kendo
import {PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {orderBy} from "@progress/kendo-data-query";
import {Grid, GridColumn} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";

// Multilingual
import {
    assignedHostsKey,
    assignMoreLicensesKey,
    hostIdKey,
    mainMessages, noLicensesPeriodKey,
} from "../../assets/text/MultilingualText";
import {useLocalization} from "@progress/kendo-react-intl";
import {useHistory} from "react-router-dom";


// context to store popover reference, when to show, and which popover to show
export const PopoverContext = createContext({
    onMouseOver: () => null,
});

const ProductPanelBars = (props) => {
    const {
        groupByProducts,
        groupByProductsNoExpired,
        showExpired,
        eaStatus,
        selectedPeriod,
        eaID,
        rowRender,
        isLoading
    } = props
    let history = useHistory();
    const {siteLanguageDefault} = useContext(UserContext);
    const localization = useLocalization();

    const [items, setItems] = useState(groupByProducts)
    useEffect(() => {
        if (showExpired) {
            setItems(JSON.parse(JSON.stringify(groupByProducts)))
        } else {
            setItems(JSON.parse(JSON.stringify(groupByProductsNoExpired)))
        }
    }, [showExpired, groupByProducts, groupByProductsNoExpired])

    return (
        <>
            {items.map((item, index) => {
                return (
                    <PanelBar
                        key={index}
                        expanded={['.0']}
                        className={'ksm-panelbar-default'}
                    >
                        <PanelBarItem
                            title={
                                <div style={{
                                    display: 'flex',
                                    gap: '0.938rem'
                                }}>
                                    <div
                                        className={"k-h5"}
                                        style={{
                                            color: 'white',
                                            margin: '0',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '85%',
                                        }}
                                    >
                                        {item.prod_num} {item.description}
                                    </div>
                                    <div
                                        style={{
                                            fontSize: '1rem',
                                            fontWeight: '300',
                                            color: 'white',
                                            lineHeight: '1.5rem',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {item.assigned_products !== 0 && (
                                            <div>
                                                {item.assigned_products} {localization.toLanguageString(assignedHostsKey, mainMessages[siteLanguageDefault][assignedHostsKey])}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                        >
                            <Grid
                                data={orderBy(item.products, [{
                                    field: "name",
                                    dir: "desc"
                                }])}
                                rowRender={rowRender}
                                scrollable={'none'}
                                sortable={false}
                            >
                                <GridColumn
                                    field="nameExcel"
                                    title={localization.toLanguageString(hostIdKey, mainMessages[siteLanguageDefault][hostIdKey])}
                                />
                            </Grid>
                        </PanelBarItem>
                    </PanelBar>
                )
            })}

            {items.length === 0 && !isLoading && (
                <>
                    <b style={{
                        color: "rgb(233, 0, 41)",
                    }}>
                        {localization.toLanguageString(noLicensesPeriodKey, mainMessages[siteLanguageDefault][noLicensesPeriodKey])}
                    </b>
                    <br/>
                    <br/>
                </>
            )}

            {eaStatus.toLowerCase() !== 'expired' &&
                (selectedPeriod['period_status'].toLowerCase() === "current" || selectedPeriod['period_status'].toLowerCase() === "open") && (
                    <Button
                        themeColor={"primary"}
                        size={"large"}
                        fillMode={"solid"}
                        rounded={"small"}
                        disabled={selectedPeriod['remain_subscription_pool'] === 0}
                        onClick={() => {
                            if (selectedPeriod['period_status'].toLowerCase() === "current") {
                                let params = '?eaid=' + eaID + '&show=PRODUCTSELECTIONCURRENTPERIOD'
                                history.push('/ea-request-license' + params)
                            } else if (selectedPeriod['period_status'].toLowerCase() === "open") {
                                let params = '?eaid=' + eaID + '&show=PRODUCTSELECTIONNEXTPERIOD'
                                history.push('/ea-request-license' + params)
                            }
                        }}
                    >
                        {localization.toLanguageString(assignMoreLicensesKey, mainMessages[siteLanguageDefault][assignMoreLicensesKey])}
                    </Button>
                )}

        </>
    )
}

export default ProductPanelBars;